@font-face {
  font-family: "Schaeffer";
  src: url("./fonts/schaeffer.woff2") format("woff2");
}

@font-face {
  font-family: "NotoMusic";
  src: url("./fonts/notomusic-regular.woff2") format("woff2");
}

body {
  margin: 0;
  padding: 0;
  background: rgb(62,78,85);
  background-image: linear-gradient(to top, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
  /* background: linear-gradient(301deg, rgba(62,78,85,1) 22%, rgba(37,63,66,1) 68%, rgba(22,38,46,1) 100%); */
  font-family: sans-serif;
}

div#root {
  height: 100vh;
  box-sizing: border-box;
}

.paper {
  height: 100%;
  overflow: hidden;
  max-width: 1024px;
  margin: auto;
  background: white;
  background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );
  border-radius: 4px;
  box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
  box-sizing: border-box;
  position: relative;
}

.song-title {
  margin: 32px 0;
  text-align: center;
  font-family: 'Schaeffer';
  font-size: 24px;
  font-weight: normal;
}

.sheet {
  padding: 0;
  margin: 0 auto;
  width: 85%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  row-gap: 24px;
}

.sheet > li {
  text-align: center;
}

.chord-name {
  font-family: 'NotoMusic';
  font-size: 24px;
}

.shuffle-button {
  position: absolute;
  width: 60px;
  height: 40px;
  padding: 9px 15px;
  border-radius: 4px;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  bottom: 10px;
  cursor: pointer;
  background: none;
  border: solid 1px rgba(207,196,179,0.7);
  background: linear-gradient(180deg, rgba(241,231,216,0.2) 13%, rgba(207,196,179,0.7) 100%);
}

.shuffle-button:active {
  padding: 10px 16px;
  background: linear-gradient(0deg, rgba(241,231,216,0.2) 13%, rgba(207,196,179,0.7) 100%);
}

.shuffle-button svg {
  opacity: 0.3;
}

.shuffle-button:hover svg {
  opacity: 0.6;
}


@media screen and (min-width: 1024px) {
  .song-title {
    margin: 40px 0 32px 0;
    font-size: 40px;
  }
}

@media screen and (min-height: 800px) {
  .sheet {
    padding: 0;
    margin: 0 auto;
    width: 85%;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 8px;
  }

  .staff {
    margin-top: 4px;
    border-right: solid 1px black;
  }

  .staff > span {
    display: block;
    border-top: solid 1px black;
    background: black;
    margin-bottom: 4px;
  }

  .sheet > li {
    text-align: left;
  }

  .chord-name {
    margin-left: 8px;
  }

  .shuffle-button {
    top: 40px;
    right: 40px;
    left: auto;
    bottom: auto;
    border: solid 1px rgba(207,196,179,0.3);
    background: none;
  }

  .shuffle-button:hover {
    border: solid 1px rgba(207,196,179,0.7);
    background: linear-gradient(180deg, rgba(241,231,216,0.2) 13%, rgba(207,196,179,0.7) 100%);
  }
}

@media screen and (min-height: 1024px) {
  .song-title {
    margin: 40px 0 32px 0;
    font-size: 40px;
  }

  .sheet {
    row-gap: 16px;
  }

  .staff {
    margin-top: 8px;
  }

  .staff > span {
    margin-bottom: 6px;
  }
}

@media screen and (min-height: 1200px) {
  .song-title {
    margin: 80px auto;
  }

  .sheet {
    row-gap: 24px;
  }

  .staff {
    margin-top: 16px;
  }
}

@media screen and (min-height: 1024px) and (min-width: 1024px) {
  div#root {
    padding: 24px;
  }
}

@media print {
  .paper {
    box-shadow: none;
    border: none;
  }

  .sheet {
    width: 100%;
    row-gap: 16px;
  }

  .song-title {
    margin: 0px 0px 20px 0px;
    font-size: 40px;
  }

  .sheet > li {
    text-align: left;
  }

  .staff {
    margin-top: 8px;
    border-right: solid 1px black;
  }

  .staff > span {
    display: block;
    border-top: solid 1px black;
    background: black;
    margin-bottom: 6px;
  }

  .chord-name {
    margin-left: 8px;
  }

  .shuffle-button {
    display: none;
  }
}
